// src/components/NavBarHamburger.tsx
'use client';

import * as React from 'react';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MenuOption, DropdownMenuOption } from '../../lib/menuItems';
import { useRouter } from 'next/navigation';
import Link from "next/link";
import Image from "next/image";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import ButtonContact from "@/src/components/ButtonContact";

const MenuItem = ({ item, toggleMenu }: { item: MenuOption; toggleMenu: () => void }) => {
    const router = useRouter();

    const handleClick = () => {
        router.push(item.href);
        toggleMenu();
    };

    return (
        <motion.div
            className="text-white cursor-pointer text-3xl py-4 flex items-center"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleClick}
        >
            {item.realLabel()}
            {(item.label === 'Resources' || item.label === 'Company') && (
                <FaArrowRight className="ml-2" />
            )}
        </motion.div>
    );
};

const DropdownMenu = ({ item, toggleMenu, goBack }: { item: DropdownMenuOption; toggleMenu: () => void; goBack: () => void }) => {
    return (
        <motion.div
            className="flex flex-col justify-center items-center w-full h-full bg-transparent text-white z-10 p-4"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
        >
            <button className="bg-transparent border-none text-white text-2xl mb-4 flex items-center" onClick={goBack}>
                <FaArrowLeft className="mr-2" />
                Back
            </button>
            {item.subOptions.map((subItem, subIndex) => (
                <motion.div key={subIndex} className="px-2">
                    <MenuItem item={subItem} toggleMenu={toggleMenu} />
                </motion.div>
            ))}
        </motion.div>
    );
};

const NavbarMenuOptions = ({ toggleMenu, setCurrentMenu }: { toggleMenu: () => void; setCurrentMenu: (menu: MenuOption | null) => void }) => {
    const filteredMenuItems = MenuOption.mainMenuItemsAll();

    return (
        <>
            {filteredMenuItems.map((item, index) => (
                item instanceof DropdownMenuOption ? (
                    <motion.div
                        key={index}
                        className="text-white cursor-pointer text-3xl py-4 flex items-center"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => setCurrentMenu(item)}
                    >
                        {item.realLabel()}
                        <FaArrowRight className="ml-2" />
                    </motion.div>
                ) : (
                    <MenuItem key={index} item={item} toggleMenu={toggleMenu} />
                )
            ))}
        </>
    );
};

const NavBarHamburger = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentMenu, setCurrentMenu] = useState<MenuOption | null>(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setCurrentMenu(null);
    };

    const goBack = () => {
        setCurrentMenu(null);
    };

    return (
        <div className="relative lg:order-1 order-2 block lg:hidden print:hidden border-0">
            {/* Hamburger and Close Icon */}
            <motion.div
                className="cursor-pointer z-50 absolute -top-2 right-0"
                onClick={toggleMenu}
                initial={false}
                animate={isOpen ? 'open' : 'closed'}
            >
                <motion.div
                    className={`block w-8 h-1 bg-white ${isOpen ? 'mb-0' : 'mb-1'}`}
                    animate={{
                        rotate: isOpen ? 45 : 0,
                        y: isOpen ? 6 : 0,
                    }}
                    transition={{ duration: 0.3 }}
                />
                <motion.div
                    className="block w-8 h-1 bg-white mb-1"
                    animate={{
                        opacity: isOpen ? 0 : 1,
                    }}
                    transition={{ duration: 0.3 }}
                />
                <motion.div
                    className="block w-8 h-1 bg-white"
                    animate={{
                        rotate: isOpen ? -45 : 0,
                        y: isOpen ? -6 : 0,
                    }}
                    transition={{ duration: 0.3 }}
                />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, height: 0, width: 0 }}
                animate={{
                    opacity: isOpen ? 1 : 0,
                    height: isOpen ? '90vh' : 0,
                    width: isOpen ? '100vw' : 0,
                }}
                transition={{ duration: 0.3 }}
                className="fixed inset-0 bg-navbarBackground z-40"
            >
                <motion.div
                    className="mx-8 flex flex-col justify-center items-center h-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isOpen ? 1 : 0 }}
                    transition={{ delay: 0.3, duration: 0.3 }}
                >
                    {currentMenu ? (
                        <DropdownMenu item={currentMenu as DropdownMenuOption} toggleMenu={toggleMenu} goBack={goBack} />
                    ) : (
                        <NavbarMenuOptions toggleMenu={toggleMenu} setCurrentMenu={setCurrentMenu} />
                    )}
                    <div className="w-full">
                        <div className="absolute top-8 left-8">
                            <Link href="/" onClick={toggleMenu}>
                                <Image
                                    src="/image/AG-Logo-Reversed-RGB-326f7.png"
                                    alt="AGNOS"
                                    width={143}
                                    height={35}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="absolute bottom-8 justify-center">
                        <ButtonContact toggleMenu={toggleMenu} />
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default NavBarHamburger;