// src/components/NavbarMainMenu.tsx
'use client';

import { MenuOption, DropdownMenuOption } from '../../lib/menuItems';
import { cn } from '../../lib/utils';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ButtonContact from '../ButtonContact';

const NavbarMainMenu = () => {
    const pathname = usePathname() || '/';
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const isCurrent = (item: MenuOption) => {
        if (pathname === '/') return false;

        const itempath = item.href + '/';
        return itempath.startsWith(pathname) || pathname.startsWith(itempath);
    };
    const menuItemClasses = `font-sofiaPro font-normal text-lg tracking-widest text-foreground flex items-center justify-right lg:justify-center px-2 xl:px-4 no-underline`;
    const menuItemActiveClasses = 'font-sofiaPro text-white underline underline-offset-8';
    const menuItemPassiveClasses = 'font-sofiaPro hover:text-white/50';

    const [openDropdown, setOpenDropdown] = useState<string | null>(null);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const handleMouseEnter = (label: string) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setOpenDropdown(label);
    };

    const handleMouseLeave = (label: string) => {
        const id = setTimeout(() => {
            if (openDropdown === label) {
                setOpenDropdown(null);
            }
        }, 300);
        setTimeoutId(id);
    };

    const handleItemClick = () => {
        setOpenDropdown(null);
    };

    const menuItem = (item: MenuOption, index: number) => {
        const additional = isCurrent(item) ? menuItemActiveClasses : menuItemPassiveClasses;

        if (item instanceof DropdownMenuOption) {
            const isAnySubItemActive = item.subOptions.some(subItem => isCurrent(subItem));

            return (
                <div
                    key={index}
                    className="relative"
                    onMouseEnter={() => handleMouseEnter(item.label)}
                    onMouseLeave={() => handleMouseLeave(item.label)}
                >
                    <button
                        className={cn(menuItemClasses, additional, 'flex items-center bg-transparent border-0')}
                        onClick={(e) => e.preventDefault()}
                    >
                        <span>{item.label}</span>
                    </button>
                    <AnimatePresence>
                        {openDropdown === item.label && (
                            <motion.ul
                                className="list-none absolute left-0 top-full mt-0 w-full bg-navbarBackground text-white rounded-md shadow-lg p-4"
                                initial={{opacity: 0, height: 0}}
                                animate={{opacity: 1, height: 'auto', width: '150px'}}
                                exit={{opacity: 0, height: 0}}
                                transition={{duration: 0.3}}
                            >
                                {item.subOptions.map((subItem, subIndex) => (
                                    <motion.li key={subIndex}>
                                        <Link
                                            href={subItem.href}
                                            className={`flex items-center no-underline text-lg tracking-widest text-foreground p-2 hover:text-white/50 `}
                                            onClick={handleItemClick}
                                        >
                                            <span className={`font-normal ${isCurrent(subItem) ? menuItemActiveClasses : 'hover:text-white/50'}`}>{subItem.realLabel()}</span>
                                        </Link>
                                    </motion.li>
                                ))}
                            </motion.ul>
                        )}
                    </AnimatePresence>
                </div>
            );
        }

        return (
            <Link href={item.href} key={index} className={cn(menuItemClasses, additional)}>
                {item.realLabel()}
            </Link>
        );
    };

    const NavbarMenuOptions = () => {
        const filteredMenuItems = MenuOption.mainMenuItemsAll();
        return <>{filteredMenuItems.map(menuItem)}</>;
    };

    return (
        <div className="h-full w-full flex items-center justify-end lg:justify-right text-sm  lg:flex-grow">
            <NavbarMenuOptions />
            <div className="z-40">
                <ButtonContact toggleMenu={toggleMenu} />
            </div>
        </div>
    );
};

export default NavbarMainMenu;