// src/components/ButtonContact.tsx
import { buttonVariants } from './ui/button'
import { cn } from '../lib/utils'
import { useRouter } from 'next/navigation'

const ButtonContact = ({ toggleMenu }: { toggleMenu: () => void }) => {
    const router = useRouter();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        toggleMenu();
        router.push('/contact/');
    };

    return (
        <a
            href="/contact/"
            onClick={handleClick}
            className={cn(
                buttonVariants({ variant: 'default', size: 'sm' }),
                'h-6 w-28 uppercase rounded-full  text-sm font-normal no-underline shadow-sm bg-white text-black p-2',
            )}
        >
            Book a Call
        </a>
    );
};

export default ButtonContact;